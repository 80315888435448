import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { environment } from '@environments/environment';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { NotificationService } from '@core/services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [CapturumButtonModule, TranslateModule],
})
export class FileUploadComponent {
  @Input() public dropFiles = true;
  @Input() public showFileList = true;
  @Input() public label: string;
  @Input() public styleClass: string;
  @Input() public icon: string;
  @Input() public accept = '*';
  @Input() public disabled = false;
  @Input() public resetFiles: boolean;

  @Input() public set files(files: FileUpload[]) {
    this._files = files || [];
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onFileChange = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onFileRemoved = new EventEmitter<any>();

  @ViewChild('fileInput') public fileInput: ElementRef;

  @ContentChild('content') public content: ElementRef;

  public dragover: boolean;
  public inputId = uuid();

  private _files: FileUpload[] = [];

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  public get files(): FileUpload[] {
    return this._files;
  }

  /**
   * Set the dragover property to true when dragging a file over the component
   *
   * @param event
   */
  public onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragover = true;
  }

  /**
   * Set the dragover property to false when leaving the component while dragging a file
   *
   * @param event
   */
  public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragover = false;
  }

  /**
   * Emit drop event when a file is dropped onto the component
   *
   * @param event
   */
  public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;

      for (const file of files) {
        this.fileReader(file);
      }
    }
  }

  /**
   * Add file to list
   *
   * @param event
   */
  public onChange(event: any): void {
    if (this.resetFiles) {
      this._files = [];
      this.resetFiles = false;
    }

    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;

      for (const file of files) {
        this.fileReader(file);
      }
    }

    event.target.value = '';
  }

  public removeFile(index: number): void {
    this.onFileRemoved.next(this.files[index]);
    this.files.splice(index, 1);
  }

  public focusInput(): void {
    this.fileInput.nativeElement.click();
  }

  private fileReader(file: File): void {
    if (this.fileSizeCheck(file.size)) {
      const fileReader = new FileReader();
      let fileType = file.type;

      if (fileType === '' && file.name.endsWith('.msg')) {
        fileType = 'application/vnd.ms-outlook';
      }

      fileReader.onloadend = () => {
        this.files.push({
          filename: file.name,
          mime_type: fileType,
          data: fileReader.result as string,
        });

        this.onFileChange.emit({
          filename: file.name,
          mime_type: fileType,
          data: fileReader.result as string,
          rawFile: file,
        });
      };

      fileReader.readAsDataURL(file);
    } else {
      this.notificationService.error(
        this.translateService.instant('general.error'),
        this.translateService.instant('general.alert.maximum-file-size-exceeded'),
      );
    }
  }

  private fileSizeCheck(bites = 0): boolean {
    const fileSizeInKb = Math.round(bites / 1024);

    return fileSizeInKb < environment.attachmentSizeInMb * 1024;
  }
}

export interface FileUpload {
  id?: string;
  filename: string;
  mime_type: string;
  data: string;
}
