import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { NgControl, NgModel, FormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i4 from 'primeng/inputswitch';
import { InputSwitchModule } from 'primeng/inputswitch';
function CapturumInputSwitchComponent_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumInputSwitchComponent_cap_validator_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumInputSwitchComponent extends ValueAccessorBase {
  constructor(validatorService, injector) {
    super();
    this.validatorService = validatorService;
    this.injector = injector;
    this.disabled = undefined;
    this.change = new EventEmitter();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  static {
    this.ɵfac = function CapturumInputSwitchComponent_Factory(t) {
      return new (t || CapturumInputSwitchComponent)(i0.ɵɵdirectiveInject(i1.ValidatorService), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumInputSwitchComponent,
      selectors: [["cap-input-switch"]],
      viewQuery: function CapturumInputSwitchComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        style: "style",
        styleClass: "styleClass",
        inputId: "inputId",
        name: "name",
        label: "label",
        disabled: "disabled",
        readonly: "readonly"
      },
      outputs: {
        change: "change"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumInputSwitchComponent)]), i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 8,
      consts: [[1, "form-element"], [3, "onChange", "ngModelChange", "ngModel", "disabled", "readonly", "styleClass"], [4, "ngIf"], [3, "control", "customName", 4, "ngIf"], [3, "control", "customName"]],
      template: function CapturumInputSwitchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "p-inputSwitch", 1);
          i0.ɵɵlistener("onChange", function CapturumInputSwitchComponent_Template_p_inputSwitch_onChange_1_listener($event) {
            return ctx.change.emit($event);
          });
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumInputSwitchComponent_Template_p_inputSwitch_ngModelChange_1_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, CapturumInputSwitchComponent_label_2_Template, 2, 1, "label", 2)(3, CapturumInputSwitchComponent_cap_validator_3_Template, 1, 2, "cap-validator", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.style);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("disabled", ctx.disabled)("readonly", ctx.readonly)("styleClass", ctx.styleClass);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.label);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgControlStatus, i2.NgModel, i3.NgIf, i4.InputSwitch, i1.CapturumValidatorComponent],
      styles: ["label[_ngcontent-%COMP%]{vertical-align:top;margin:0 0 0 .5em;line-height:1.75em}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputSwitchComponent, [{
    type: Component,
    args: [{
      selector: 'cap-input-switch',
      providers: [ValueAccessorBase.getProviderConfig(CapturumInputSwitchComponent)],
      template: "<div class=\"form-element\">\n    <p-inputSwitch (onChange)=\"change.emit($event)\" [(ngModel)]=\"value\" [disabled]=\"disabled\" [readonly]=\"readonly\"\n        [styleClass]=\"styleClass\" [style]=\"style\">\n    </p-inputSwitch>\n    <label *ngIf=\"label\">{{ label }}</label>\n    <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n",
      styles: ["label{vertical-align:top;margin:0 0 0 .5em;line-height:1.75em}\n"]
    }]
  }], () => [{
    type: i1.ValidatorService
  }, {
    type: i0.Injector
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class CapturumInputSwitchModule {
  static {
    this.ɵfac = function CapturumInputSwitchModule_Factory(t) {
      return new (t || CapturumInputSwitchModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumInputSwitchModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [FormsModule, CommonModule, InputSwitchModule, CapturumSharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputSwitchModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumInputSwitchComponent],
      exports: [CapturumInputSwitchComponent],
      imports: [FormsModule, CommonModule, InputSwitchModule, CapturumSharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumInputSwitchComponent, CapturumInputSwitchModule };
