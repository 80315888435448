import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i4 from 'primeng/inputmask';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
const _c0 = [[["", 8, "tooltip-content"]]];
const _c1 = [".tooltip-content"];
const _c2 = a0 => ({
  "justify-content": a0
});
function CapturumInputMaskComponent_label_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumInputMaskComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtemplate(1, CapturumInputMaskComponent_label_1_span_1_Template, 2, 1, "span", 5);
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction1(3, _c2, ctx_r0.label ? "space-between" : "flex-end"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.label);
  }
}
function CapturumInputMaskComponent_cap_validator_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumInputMaskComponent extends ValueAccessorBase {
  constructor(injector, validatorService) {
    super();
    this.injector = injector;
    this.validatorService = validatorService;
    /* Disabled input */
    this.disabled = undefined;
    /* Callback on input blur */
    this.blur = new EventEmitter();
    /* Callback on complete pattern */
    this.complete = new EventEmitter();
    /* Callback on input focus */
    this.focus = new EventEmitter();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  onBlur() {
    this.blur.emit();
    this.touch();
  }
  onFocus() {
    this.focus.emit();
  }
  static {
    this.ɵfac = function CapturumInputMaskComponent_Factory(t) {
      return new (t || CapturumInputMaskComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ValidatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumInputMaskComponent,
      selectors: [["cap-input-mask"]],
      inputs: {
        placeholder: "placeholder",
        label: "label",
        mask: "mask",
        disabled: "disabled",
        hasTooltip: "hasTooltip"
      },
      outputs: {
        blur: "blur",
        complete: "complete",
        focus: "focus"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumInputMaskComponent)]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 5,
      vars: 7,
      consts: [[1, "form-element"], [3, "style", 4, "ngIf"], [1, "p-inputgroup"], [3, "ngModelChange", "blur", "onComplete", "focus", "ngModel", "disabled", "mask", "unmask", "placeholder"], [3, "control", "customName", 4, "ngIf"], [4, "ngIf"], [3, "control", "customName"]],
      template: function CapturumInputMaskComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CapturumInputMaskComponent_label_1_Template, 3, 5, "label", 1);
          i0.ɵɵelementStart(2, "div", 2)(3, "p-inputMask", 3);
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumInputMaskComponent_Template_p_inputMask_ngModelChange_3_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵlistener("blur", function CapturumInputMaskComponent_Template_p_inputMask_blur_3_listener() {
            return ctx.onBlur();
          })("onComplete", function CapturumInputMaskComponent_Template_p_inputMask_onComplete_3_listener() {
            return ctx.complete == null ? null : ctx.complete.emit();
          })("focus", function CapturumInputMaskComponent_Template_p_inputMask_focus_3_listener() {
            return ctx.onFocus();
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(4, CapturumInputMaskComponent_cap_validator_4_Template, 1, 2, "cap-validator", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.label || ctx.hasTooltip);
          i0.ɵɵadvance(2);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("disabled", ctx.disabled)("mask", ctx.mask)("unmask", true)("placeholder", ctx.placeholder);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.InputMask, i1.CapturumValidatorComponent],
      styles: [".ng-invalid[_nghost-%COMP%]   input.ng-touched[_ngcontent-%COMP%]{border:1px solid var(--error-color)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputMaskComponent, [{
    type: Component,
    args: [{
      selector: 'cap-input-mask',
      providers: [ValueAccessorBase.getProviderConfig(CapturumInputMaskComponent)],
      template: "<div class=\"form-element\">\n    <label *ngIf=\"label || hasTooltip\" [style]=\"{ 'justify-content': label ? 'space-between' : 'flex-end' }\">\n        <span *ngIf=\"label\">{{ label }}</span>\n\n        <ng-content select=\".tooltip-content\"></ng-content>\n    </label>\n\n    <div class=\"p-inputgroup\">\n        <p-inputMask [(ngModel)]=\"value\"\n                     [disabled]=\"disabled\"\n                     [mask]=\"mask\"\n                     [unmask]=\"true\"\n                     [placeholder]=\"placeholder\"\n                     (blur)=\"onBlur()\"\n                     (onComplete)=\"complete?.emit()\"\n                     (focus)=\"onFocus()\">\n        </p-inputMask>\n    </div>\n\n    <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n",
      styles: [":host.ng-invalid input.ng-touched{border:1px solid var(--error-color)}\n"]
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.ValidatorService
  }], {
    placeholder: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    mask: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    hasTooltip: [{
      type: Input
    }],
    blur: [{
      type: Output
    }],
    complete: [{
      type: Output
    }],
    focus: [{
      type: Output
    }]
  });
})();
class CapturumInputMaskModule {
  static {
    this.ɵfac = function CapturumInputMaskModule_Factory(t) {
      return new (t || CapturumInputMaskModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumInputMaskModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, InputMaskModule, CapturumSharedModule, OverlayPanelModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumInputMaskModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumInputMaskComponent],
      exports: [CapturumInputMaskComponent],
      imports: [CommonModule, FormsModule, InputMaskModule, CapturumSharedModule, OverlayPanelModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumInputMaskComponent, CapturumInputMaskModule };
