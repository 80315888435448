import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl, NgModel, FormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
const _c0 = ["textarea"];
const _c1 = [[["", 8, "tooltip-content"]]];
const _c2 = [".tooltip-content"];
const _c3 = a0 => ({
  "justify-content": a0
});
const _c4 = a0 => ({
  "width.%": a0
});
function CapturumTextareaComponent_label_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumTextareaComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtemplate(1, CapturumTextareaComponent_label_1_span_1_Template, 2, 1, "span", 7);
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction1(3, _c3, ctx_r0.label ? "space-between" : "flex-end"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.label);
  }
}
function CapturumTextareaComponent_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CapturumTextareaComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CapturumTextareaComponent_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const defaultTemplate_r2 = i0.ɵɵreference(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", defaultTemplate_r2);
  }
}
function CapturumTextareaComponent_ng_template_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function CapturumTextareaComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵtemplate(1, CapturumTextareaComponent_ng_template_3_ng_container_1_Template, 1, 0, "ng-container", 8);
    i0.ɵɵelementStart(2, "label", 10);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const defaultTemplate_r2 = i0.ɵɵreference(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", defaultTemplate_r2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumTextareaComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "textarea", 11, 2);
    i0.ɵɵtwoWayListener("ngModelChange", function CapturumTextareaComponent_ng_template_5_Template_textarea_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r0.value, $event) || (ctx_r0.value = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵlistener("focus", function CapturumTextareaComponent_ng_template_5_Template_textarea_focus_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.onFocus());
    });
    i0.ɵɵtext(2, "    ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("populated", ctx_r0.value);
    i0.ɵɵtwoWayProperty("ngModel", ctx_r0.value);
    i0.ɵɵproperty("cols", ctx_r0.cols)("disabled", ctx_r0.disabled)("ngStyle", i0.ɵɵpureFunction1(9, _c4, !ctx_r0.label ? "100" : null))("placeholder", ctx_r0.placeholder)("readOnly", ctx_r0.readonly)("rows", ctx_r0.rows);
  }
}
function CapturumTextareaComponent_cap_validator_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 12);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumTextareaComponent extends ValueAccessorBase {
  constructor(injector, validatorService) {
    super();
    this.injector = injector;
    this.validatorService = validatorService;
    this.cols = 30;
    this.rows = 10;
    this.disabled = undefined;
    this.placeholder = '';
    /**
     * Float label
     */
    this.floatLabel = false;
    this.resize = new EventEmitter();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  onFocus() {
    this.control.markAsTouched();
    if (this.selectOnFocus) {
      this.textarea?.nativeElement?.select();
    }
  }
  static {
    this.ɵfac = function CapturumTextareaComponent_Factory(t) {
      return new (t || CapturumTextareaComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ValidatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumTextareaComponent,
      selectors: [["cap-textarea"]],
      viewQuery: function CapturumTextareaComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textarea = _t.first);
        }
      },
      inputs: {
        autoResize: "autoResize",
        cols: "cols",
        rows: "rows",
        disabled: "disabled",
        placeholder: "placeholder",
        label: "label",
        readonly: "readonly",
        hasTooltip: "hasTooltip",
        selectOnFocus: "selectOnFocus",
        floatLabel: "floatLabel",
        required: "required"
      },
      outputs: {
        resize: "resize"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumTextareaComponent)]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c2,
      decls: 8,
      vars: 4,
      consts: [["floatingInput", ""], ["defaultTemplate", ""], ["textarea", ""], [1, "form-element"], [3, "style", 4, "ngIf"], [4, "ngIf", "ngIfElse"], [3, "control", "customName", 4, "ngIf"], [4, "ngIf"], [4, "ngTemplateOutlet"], [1, "p-float-label", "textarea-float-label"], ["htmlFor", "float-label", 1, "cpb-float-label"], [1, "cap-textarea", 3, "ngModelChange", "focus", "ngModel", "cols", "disabled", "ngStyle", "placeholder", "readOnly", "rows"], [3, "control", "customName"]],
      template: function CapturumTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 3);
          i0.ɵɵtemplate(1, CapturumTextareaComponent_label_1_Template, 3, 5, "label", 4)(2, CapturumTextareaComponent_ng_container_2_Template, 2, 1, "ng-container", 5)(3, CapturumTextareaComponent_ng_template_3_Template, 4, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(5, CapturumTextareaComponent_ng_template_5_Template, 3, 11, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(7, CapturumTextareaComponent_cap_validator_7_Template, 1, 2, "cap-validator", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const floatingInput_r4 = i0.ɵɵreference(4);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", (ctx.label || ctx.hasTooltip) && !ctx.floatLabel);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.floatLabel)("ngIfElse", floatingInput_r4);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel, i1.CapturumValidatorComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumTextareaComponent, [{
    type: Component,
    args: [{
      selector: 'cap-textarea',
      providers: [ValueAccessorBase.getProviderConfig(CapturumTextareaComponent)],
      template: "<div class=\"form-element\">\n  <label *ngIf=\"(label || hasTooltip) && !floatLabel\"\n         [style]=\"{ 'justify-content': label ? 'space-between' : 'flex-end' }\">\n    <span *ngIf=\"label\">{{ label }}</span>\n\n    <ng-content select=\".tooltip-content\"></ng-content>\n  </label>\n\n  <ng-container *ngIf=\"!floatLabel; else floatingInput\">\n    <ng-container *ngTemplateOutlet=\"defaultTemplate\"></ng-container>\n  </ng-container>\n\n  <ng-template #floatingInput>\n      <span class=\"p-float-label textarea-float-label\">\n        <ng-container *ngTemplateOutlet=\"defaultTemplate\"></ng-container>\n        <label class=\"cpb-float-label\" htmlFor=\"float-label\">{{ label }}</label>\n      </span>\n  </ng-template>\n\n  <ng-template #defaultTemplate>\n     <textarea class=\"cap-textarea\"\n               #textarea\n               [(ngModel)]=\"value\"\n               [class.populated]=\"value\"\n               [cols]=\"cols\"\n               [disabled]=\"disabled\"\n               [ngStyle]=\"{'width.%': !label ? '100' : null}\"\n               [placeholder]=\"placeholder\"\n               [readOnly]=\"readonly\"\n               [rows]=\"rows\"\n               (focus)=\"onFocus()\">\n    </textarea>\n  </ng-template>\n\n  <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n"
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.ValidatorService
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    autoResize: [{
      type: Input
    }],
    cols: [{
      type: Input
    }],
    rows: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    hasTooltip: [{
      type: Input
    }],
    selectOnFocus: [{
      type: Input
    }],
    floatLabel: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    resize: [{
      type: Output
    }],
    textarea: [{
      type: ViewChild,
      args: ['textarea']
    }]
  });
})();
class CapturumTextareaModule {
  static {
    this.ɵfac = function CapturumTextareaModule_Factory(t) {
      return new (t || CapturumTextareaModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumTextareaModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, InputTextareaModule, CapturumSharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumTextareaModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumTextareaComponent],
      exports: [CapturumTextareaComponent],
      imports: [CommonModule, FormsModule, InputTextareaModule, CapturumSharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumTextareaComponent, CapturumTextareaModule };
