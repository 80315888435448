import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/forms';
import { NgModel, FormsModule } from '@angular/forms';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i2 from 'primeng/selectbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
class CapturumSelectButtonComponent extends ValueAccessorBase {
  constructor() {
    super(...arguments);
    /**
     * Index of the element in tabbing order
     */
    this.tabindex = 0;
    /**
     * Style class of the component
     */
    this.styleClass = '';
    /**
     * When present, it specifies that the element should be disabled
     */
    this.disabled = undefined;
    /**
     * Callback to invoke when a button is clicked
     */
    this.optionClick = new EventEmitter();
    /**
     * Callback to invoke when value changes
     */
    this.change = new EventEmitter();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCapturumSelectButtonComponent_BaseFactory;
      return function CapturumSelectButtonComponent_Factory(t) {
        return (ɵCapturumSelectButtonComponent_BaseFactory || (ɵCapturumSelectButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CapturumSelectButtonComponent)))(t || CapturumSelectButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumSelectButtonComponent,
      selectors: [["cap-select-button"]],
      viewQuery: function CapturumSelectButtonComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        tabindex: "tabindex",
        multiple: "multiple",
        unselectable: "unselectable",
        options: "options",
        style: "style",
        styleClass: "styleClass",
        disabled: "disabled",
        dataKey: "dataKey",
        optionLabel: "optionLabel",
        optionValue: "optionValue"
      },
      outputs: {
        optionClick: "optionClick",
        change: "change"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumSelectButtonComponent)]), i0.ɵɵInheritDefinitionFeature],
      decls: 1,
      vars: 12,
      consts: [[3, "ngModelChange", "onChange", "onOptionClick", "optionLabel", "optionValue", "dataKey", "styleClass", "multiple", "unselectable", "tabindex", "ngModel", "disabled", "options"]],
      template: function CapturumSelectButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p-selectButton", 0);
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumSelectButtonComponent_Template_p_selectButton_ngModelChange_0_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵlistener("onChange", function CapturumSelectButtonComponent_Template_p_selectButton_onChange_0_listener($event) {
            return ctx.change.emit($event);
          })("onOptionClick", function CapturumSelectButtonComponent_Template_p_selectButton_onOptionClick_0_listener($event) {
            return ctx.optionClick.emit($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleMap(ctx.style);
          i0.ɵɵproperty("optionLabel", ctx.optionLabel)("optionValue", ctx.optionValue)("dataKey", ctx.dataKey)("styleClass", "cap-select-button " + ctx.styleClass)("multiple", ctx.multiple)("unselectable", ctx.unselectable)("tabindex", ctx.tabindex);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("disabled", ctx.disabled)("options", ctx.options);
        }
      },
      dependencies: [i1.NgControlStatus, i1.NgModel, i2.SelectButton]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumSelectButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cap-select-button',
      providers: [ValueAccessorBase.getProviderConfig(CapturumSelectButtonComponent)],
      template: "<p-selectButton\n  [optionLabel]=\"optionLabel\"\n  [optionValue]=\"optionValue\"\n  [dataKey]=\"dataKey\"\n  [styleClass]=\"'cap-select-button ' + styleClass\"\n  [style]=\"style\"\n  [multiple]=\"multiple\"\n  [unselectable]=\"unselectable\"\n  [tabindex]=\"tabindex\"\n  [(ngModel)]=\"value\"\n  [disabled]=\"disabled\"\n  [options]=\"options\"\n  (onChange)=\"change.emit($event)\"\n  (onOptionClick)=\"optionClick.emit($event)\">\n</p-selectButton>\n"
    }]
  }], null, {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    tabindex: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    unselectable: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    dataKey: [{
      type: Input
    }],
    optionLabel: [{
      type: Input
    }],
    optionValue: [{
      type: Input
    }],
    optionClick: [{
      type: Output
    }],
    change: [{
      type: Output
    }]
  });
})();
class CapturumSelectButtonModule {
  static {
    this.ɵfac = function CapturumSelectButtonModule_Factory(t) {
      return new (t || CapturumSelectButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumSelectButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, SelectButtonModule, CapturumSharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumSelectButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumSelectButtonComponent],
      exports: [CapturumSelectButtonComponent],
      imports: [CommonModule, FormsModule, SelectButtonModule, CapturumSharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumSelectButtonComponent, CapturumSelectButtonModule };
