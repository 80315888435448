import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { NgControl, NgModel, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from 'primeng/colorpicker';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumButtonModule } from '@capturum/ui/button';
const _c0 = [[["", 8, "tooltip-content"]]];
const _c1 = [".tooltip-content"];
const _c2 = a0 => ({
  "justify-content": a0
});
function CapturumColorPickerComponent_label_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function CapturumColorPickerComponent_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label");
    i0.ɵɵtemplate(1, CapturumColorPickerComponent_label_1_span_1_Template, 2, 1, "span", 5);
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(i0.ɵɵpureFunction1(3, _c2, ctx_r0.label ? "space-between" : "flex-end"));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.label);
  }
}
function CapturumColorPickerComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0, 6);
    i0.ɵɵelementStart(1, "div", 7)(2, "div", 8)(3, "input", 9);
    i0.ɵɵlistener("change", function CapturumColorPickerComponent_ng_container_3_Template_input_change_3_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.pickColor($event));
    });
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formGroup", ctx_r0.form);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("value", ctx_r0.value ? ctx_r0.value : "")("placeholder", ctx_r0.placeholder);
  }
}
function CapturumColorPickerComponent_cap_validator_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumColorPickerComponent extends ValueAccessorBase {
  constructor(injector, validatorService, formBuilder) {
    super();
    this.injector = injector;
    this.validatorService = validatorService;
    this.formBuilder = formBuilder;
    this.format = 'hex';
    this.disabled = undefined;
    this.label = '';
    this.placeholder = '';
    this.autoZIndex = true;
    this.baseZIndex = 0;
    this.showTransitionOptions = '225ms ease-out';
    this.hideTransitionOptions = '195ms ease-in';
    this.displayInput = true;
    this.changeColor = new EventEmitter();
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      colorPickerInput: ['']
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  pickColor({
    target
  }) {
    if (this.isValidColor(target.value)) {
      const color = this.isHex(target.value, 3) ? this.toHexColor(target.value) : target.value;
      this.value = color;
      this.form.controls.colorPickerInput.setValue(color);
      this.changeColor.emit(color);
    } else {
      this.form.controls.colorPickerInput.setValue(this.value);
    }
  }
  isValidColor(color) {
    return this.isHex(color, 3) || this.isHex(color, 6);
  }
  isHex(color, length = 6) {
    const regExp = new RegExp(`^#([0-9A-F]){${length}}$`, 'i');
    return regExp.test(color);
  }
  toHexColor(color) {
    return '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
  }
  static {
    this.ɵfac = function CapturumColorPickerComponent_Factory(t) {
      return new (t || CapturumColorPickerComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ValidatorService), i0.ɵɵdirectiveInject(i2.FormBuilder));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumColorPickerComponent,
      selectors: [["cap-color-picker"]],
      viewQuery: function CapturumColorPickerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        style: "style",
        styleClass: "styleClass",
        inline: "inline",
        format: "format",
        appendTo: "appendTo",
        disabled: "disabled",
        tabindex: "tabindex",
        label: "label",
        placeholder: "placeholder",
        inputId: "inputId",
        autoZIndex: "autoZIndex",
        baseZIndex: "baseZIndex",
        showTransitionOptions: "showTransitionOptions",
        hideTransitionOptions: "hideTransitionOptions",
        displayInput: "displayInput",
        hasTooltip: "hasTooltip"
      },
      outputs: {
        changeColor: "changeColor"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumColorPickerComponent)]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 5,
      vars: 13,
      consts: [[1, "form-element"], [3, "style", 4, "ngIf"], [3, "onChange", "ngModelChange", "ngModel", "appendTo", "disabled", "format", "hideTransitionOptions", "inline", "showTransitionOptions", "styleClass"], [3, "formGroup", 4, "ngIf"], [3, "control", "customName", 4, "ngIf"], [4, "ngIf"], [3, "formGroup"], [1, "form-element", "colorpicker-input"], [1, "p-inputgroup", "colorpicker-border"], ["formControlName", "colorPickerInput", 1, "p-inputtext", "colorpicker-input-border", 3, "change", "value", "placeholder"], [3, "control", "customName"]],
      template: function CapturumColorPickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CapturumColorPickerComponent_label_1_Template, 3, 5, "label", 1);
          i0.ɵɵelementStart(2, "p-colorPicker", 2);
          i0.ɵɵlistener("onChange", function CapturumColorPickerComponent_Template_p_colorPicker_onChange_2_listener($event) {
            return ctx.changeColor.emit($event.value);
          });
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumColorPickerComponent_Template_p_colorPicker_ngModelChange_2_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CapturumColorPickerComponent_ng_container_3_Template, 4, 3, "ng-container", 3)(4, CapturumColorPickerComponent_cap_validator_4_Template, 1, 2, "cap-validator", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.label || ctx.hasTooltip);
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.style || null);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("appendTo", ctx.appendTo || null)("disabled", ctx.disabled || false)("format", "hex")("hideTransitionOptions", ctx.hideTransitionOptions || "195ms ease-in")("inline", ctx.inline)("showTransitionOptions", ctx.showTransitionOptions || "225ms ease-out")("styleClass", ctx.styleClass || null);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.displayInput);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i3.NgIf, i2.DefaultValueAccessor, i2.NgControlStatus, i2.NgControlStatusGroup, i2.NgModel, i2.FormGroupDirective, i2.FormControlName, i4.ColorPicker, i1.CapturumValidatorComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumColorPickerComponent, [{
    type: Component,
    args: [{
      selector: 'cap-color-picker',
      providers: [ValueAccessorBase.getProviderConfig(CapturumColorPickerComponent)],
      template: "<div class=\"form-element\">\n    <label *ngIf=\"label || hasTooltip\" [style]=\"{ 'justify-content': label ? 'space-between' : 'flex-end' }\">\n        <span *ngIf=\"label\">{{ label }}</span>\n\n        <ng-content select=\".tooltip-content\"></ng-content>\n    </label>\n\n    <p-colorPicker (onChange)=\"changeColor.emit($event.value)\"\n                   [(ngModel)]=\"value\"\n                   [appendTo]=\"appendTo || null\"\n                   [disabled]=\"disabled || false\"\n                   [format]=\"'hex'\"\n                   [hideTransitionOptions]=\"hideTransitionOptions || '195ms ease-in'\"\n                   [inline]=\"inline\"\n                   [showTransitionOptions]=\"showTransitionOptions || '225ms ease-out'\"\n                   [styleClass]=\"styleClass || null\"\n                   [style]=\"style || null\">\n    </p-colorPicker>\n\n    <ng-container *ngIf=\"displayInput\" [formGroup]=\"form\">\n        <div class=\"form-element colorpicker-input\">\n            <div class=\"p-inputgroup colorpicker-border\">\n                <input (change)=\"pickColor($event)\"\n                       [value]=\"value ? value: ''\"\n                       formControlName=\"colorPickerInput\"\n                       [placeholder]=\"placeholder\"\n                       class=\"p-inputtext colorpicker-input-border\" />\n            </div>\n        </div>\n    </ng-container>\n\n    <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n"
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.ValidatorService
  }, {
    type: i2.FormBuilder
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    inline: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    autoZIndex: [{
      type: Input
    }],
    baseZIndex: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    displayInput: [{
      type: Input
    }],
    hasTooltip: [{
      type: Input
    }],
    changeColor: [{
      type: Output
    }]
  });
})();
class CapturumColorPickerModule {
  static {
    this.ɵfac = function CapturumColorPickerModule_Factory(t) {
      return new (t || CapturumColorPickerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumColorPickerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ReactiveFormsModule, ColorPickerModule, CapturumInputModule, CapturumSharedModule, CapturumButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumColorPickerModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumColorPickerComponent],
      exports: [CapturumColorPickerComponent],
      imports: [CommonModule, FormsModule, ReactiveFormsModule, ColorPickerModule, CapturumInputModule, CapturumSharedModule, CapturumButtonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumColorPickerComponent, CapturumColorPickerModule };
