import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { NgControl, NG_VALUE_ACCESSOR, NgModel, FormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i4 from 'primeng/chips';
import { ChipsModule } from 'primeng/chips';
function CapturumChipsComponent_cap_validator_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumChipsComponent extends ValueAccessorBase {
  constructor(injector, validatorService) {
    super();
    this.injector = injector;
    this.validatorService = validatorService;
    /**
     * Style class of the element
     */
    this.styleClass = '';
    /**
     * When present, it specifies that the element should be disabled
     */
    this.disabled = undefined;
    /**
     * Whether to allow duplicate values or not
     */
    this.allowDuplicate = true;
    /**
     * Callback to invoke when a value is added
     */
    this.add = new EventEmitter();
    /**
     * Callback to invoke when a value is removed
     */
    this.remove = new EventEmitter();
    /**
     * Callback to invoke when a input is focused
     */
    this.focus = new EventEmitter();
    /**
     * Callback to invoke when a input loses focus
     */
    this.blur = new EventEmitter();
    /**
     * Callback to invoke when a chip is clicked
     */
    this.chipClick = new EventEmitter();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  static {
    this.ɵfac = function CapturumChipsComponent_Factory(t) {
      return new (t || CapturumChipsComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ValidatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumChipsComponent,
      selectors: [["cap-chips"]],
      viewQuery: function CapturumChipsComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        style: "style",
        styleClass: "styleClass",
        disabled: "disabled",
        field: "field",
        placeholder: "placeholder",
        label: "label",
        max: "max",
        tabindex: "tabindex",
        inputId: "inputId",
        allowDuplicate: "allowDuplicate",
        inputStyle: "inputStyle",
        inputStyleClass: "inputStyleClass",
        addOnTab: "addOnTab",
        addOnBlur: "addOnBlur"
      },
      outputs: {
        add: "add",
        remove: "remove",
        focus: "focus",
        blur: "blur",
        chipClick: "chipClick"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CapturumChipsComponent,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature],
      decls: 3,
      vars: 16,
      consts: [[1, "form-element"], [3, "onAdd", "onBlur", "onChipClick", "onRemove", "ngModelChange", "ngModel", "addOnBlur", "addOnTab", "allowDuplicate", "disabled", "inputId", "inputStyleClass", "inputStyle", "max", "field", "placeholder", "styleClass", "tabindex"], [3, "control", "customName", 4, "ngIf"], [3, "control", "customName"]],
      template: function CapturumChipsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "p-chips", 1);
          i0.ɵɵlistener("onAdd", function CapturumChipsComponent_Template_p_chips_onAdd_1_listener($event) {
            return ctx.add.emit($event);
          })("onBlur", function CapturumChipsComponent_Template_p_chips_onBlur_1_listener($event) {
            return ctx.blur.emit($event);
          })("onChipClick", function CapturumChipsComponent_Template_p_chips_onChipClick_1_listener($event) {
            return ctx.chipClick.emit($event);
          })("onRemove", function CapturumChipsComponent_Template_p_chips_onRemove_1_listener($event) {
            return ctx.remove.emit($event);
          });
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumChipsComponent_Template_p_chips_ngModelChange_1_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, CapturumChipsComponent_cap_validator_2_Template, 1, 2, "cap-validator", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.style || null);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("addOnBlur", ctx.addOnBlur || false)("addOnTab", ctx.addOnTab || true)("allowDuplicate", ctx.allowDuplicate || true)("disabled", ctx.disabled || false)("inputId", ctx.inputId)("inputStyleClass", ctx.inputStyleClass || null)("inputStyle", ctx.inputStyle || null)("max", ctx.max || null)("field", ctx.field)("placeholder", ctx.placeholder || null)("styleClass", "cap-chips " + ctx.styleClass)("tabindex", ctx.tabindex || null);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.Chips, i1.CapturumValidatorComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumChipsComponent, [{
    type: Component,
    args: [{
      selector: 'cap-chips',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CapturumChipsComponent,
        multi: true
      }],
      template: "<div class=\"form-element\">\n  <p-chips\n    (onAdd)=\"add.emit($event)\"\n    (onBlur)=\"blur.emit($event)\"\n    (onChipClick)=\"chipClick.emit($event)\"\n    (onRemove)=\"remove.emit($event)\"\n    [(ngModel)]=\"value\"\n    [addOnBlur]=\"addOnBlur || false\"\n    [addOnTab]=\"addOnTab || true\"\n    [allowDuplicate]=\"allowDuplicate || true\"\n    [disabled]=\"disabled || false\"\n    [inputId]=\"inputId\"\n    [inputStyleClass]=\"inputStyleClass || null\"\n    [inputStyle]=\"inputStyle || null\"\n    [max]=\"max || null\"\n    [field]=\"field\"\n    [placeholder]=\"placeholder || null\"\n    [styleClass]=\"'cap-chips ' + styleClass\"\n    [style]=\"style || null\"\n    [tabindex]=\"tabindex || null\">\n  </p-chips>\n\n  <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n"
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.ValidatorService
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    field: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    allowDuplicate: [{
      type: Input
    }],
    inputStyle: [{
      type: Input
    }],
    inputStyleClass: [{
      type: Input
    }],
    addOnTab: [{
      type: Input
    }],
    addOnBlur: [{
      type: Input
    }],
    add: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    chipClick: [{
      type: Output
    }]
  });
})();
class CapturumChipsModule {
  static {
    this.ɵfac = function CapturumChipsModule_Factory(t) {
      return new (t || CapturumChipsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumChipsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ChipsModule, CapturumSharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumChipsModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumChipsComponent],
      exports: [CapturumChipsComponent],
      imports: [CommonModule, FormsModule, ChipsModule, CapturumSharedModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumChipsComponent, CapturumChipsModule };
